<template>
  <div>
    <div v-for="(item, index) in poiList" :key="index" class="ele-address-item" @click="choosePoi(item)">
      <div>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#iconzhiyuandidian4" />
        </svg>
      </div>
      <div class="ele_address_main text_ellipsis">
        <p>{{ item.name }}</p>
        <p v-if="item.address.length != 0" class="sub_name">{{ item.address }}</p>
        <p v-else class="sub_name">{{ item.pname }}{{ item.cityname }}{{ item.adname }}</p>
        <div v-if="item.children && item.children.length" class="sub_address">
          <div v-for="childrenItem of Deduplication(item.children)" :key="childrenItem.id" :ref="childrenItem.sname" @click="choosePoi(childrenItem)">
            <span>{{ childrenItem.sname }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['choosePoi', 'poiList'],
  mounted() {
    console.log('poiList', this.poiList);
  },
  methods:{
    Deduplication(children) {
      let map = new Map();
      for (let item of children) {
        if (!map.has(item.sname)) {
          map.set(item.sname, item);
        }
      }
      return [...map.values()];
    }
  }
};
</script>

<style lang="scss" scoped>
$txt_color: #13161A;
$sub_txt_color: #909399;
.ele-address-item-active {
  background-color: var(--themeColor);
}
.ele-address-item + .ele-address-item {
  border-top: 1px solid #f5f5f5;
}
.ele_address_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  p:first-child {
      text-align: left;
      font-size: 0.16rem;
      color: $txt_color;
    }
  .sub_name {
    text-align: left;
    color: $sub_txt_color;
    // color: red;
    font-size: 0.12rem;
  }
}
.text_ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sub_address {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    min-width: .8rem;
    padding: 0.02rem;
    border: 1px solid #f5f5f5;
    display: flex;
    margin: .05rem;
    justify-content: center;
    color: $sub_txt_color;
    font-size: .12rem;
    // min-height: .04rem;
  }
}
.ele-address-item {
  display: flex;
  padding: .1rem .15rem;
  .icon{
    color: $sub_txt_color;
    margin-right: .1rem;
    width: .15rem;
  }

}
</style>
