<template>
  <div v-if="show">
    <mt-popup v-model="show" position="bottom" class="popup_box">
      <div class="ele-container ele-address-map">
        <mapHead :goBack="goBack" :city="city" :chooseCity="chooseCity">
          <template slot="inputBox">
            <div @click="opeSearch" class="inputBox">
              <input type="search" id="pickerInput" placeholder="小区/写字楼/学校等" />
            </div>
          </template>
        </mapHead>
        <div :class="poiList.length ? 'eleMap' : 'eleMap-all'" id="containerMap"></div>
        <mt-popup v-model="showItem" class="bottom-pup" position="bottom">
          <mapPoi :choosePoi="choosePoi" :poiList="poiList"></mapPoi>
        </mt-popup>
      </div>
      <SearchCityCar @on-ok="chooseCityOK" :cityList="cityList" v="2.0" ref="eventCity"></SearchCityCar>
      <mapSearch :UpdateAddress="UpdateAddress" :city="city" @chooseCity="chooseCity" @close="close" ref="mapSearch" />
    </mt-popup>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import mapSearch from './mapSearch.vue';
import mapHead from './mapHead.vue';
import mapPoi from './mapPoi.vue';
import positionImg from '@/assets/images/position.png';
import SearchCityCar from '@/views/common/SearchCityCar/SearchCityCar';
export default {
  name: 'AddressMap',
  props: ['oldCity'],
  data() {
    return {
      timeer: null,
      // geoData: {},
      isUpdateEndAddress: false,
      cityList: [],
      showItem: true,
      poiList: [],
      address: {},
      show: false,
      city: '',
      markers: [],
      colors: {
        eventcool: '#3875FE',
        pfizer: '#E60012'
      },
      cityId: '',
      isOnline: false, // 线上车
      isNovartis: this.SmartStorage.get('tenant_code') == 'novartis' || false // 是否诺华山德士租户
    };
  },
  watch: {
    isShowPopup() {
      if (this.show) {
        this.setRouteListeners('priceCuponClose'); //保存当前路由返回事件记录
        this.$root.$eventHub.$on('priceCuponClose', data => {
          this.priceCuponClose();
          this.$root.$eventHub.$off('priceCuponClose');
        });
      } else {
        this.removeRouteListeners('priceCuponClose'); //返回删除记录
      }
    }
  },
  beforeDestroy() {
    this.show = false;
    clearTimeout(this.timeer);
    this.map && this.map.destroy();
  },
  components: { mapSearch, mapHead, mapPoi, SearchCityCar },
  computed: {
    ...mapGetters(['eleAddress'])
  },
  mounted() {
    console.log('this.isNovartis====', this.isNovartis);
    this.getCityList();
  },
  methods: {
    ...mapMutations(['SET_ELE_ADDRESS']),
    chooseCityOK(e) {
      console.log(e);
      // if (
      //   ["dsm"].includes(this.$cookies.get("tenant")) &&
      //   this.SmartStorage.get("eventData").userEventType == "approval"
      // ) {
      //   let citys = this.SmartStorage.get("eventData").cityDictVal.split(",");
      //   let flage=false
      //   for (let val of citys) {
      //     if (e.txt.includes(val)) {
      //       flage=true;
      //     }
      //   }
      //   if(!flage){
      //     this.$iToast("差旅不能跨城市用车，请在同一城市内选择出发地和目的地");
      //     return false;
      //   }
      // }
      this.city = e.txt;
      this.map.setCenter([e.itsExtData.gps.longitude, e.itsExtData.gps.latitude]);
      // console.log(e);
    },
    getCityList() {
      let params = {
        keyCode: 'CPC-City'
      };
      let _this = this;
      this.services.queryDict(params).then(res => {
        if (res.success) {
          _this.cityList = res.content;
        }
      });
    },
    chooseCity() {
      // if(this.isNovartis){
      //   this.$iToast("只能在会议城市用车");
      //   return false;
      // }
      this.$refs.eventCity.openPop();
    },
    opeSearch() {
      this.$refs.mapSearch.show(this.isUpdateEndAddress);
    },
    cityOk(city) {
      this.city = city.txt;
      this.map.setCenter([city.itsExtData.gps.longitude, city.itsExtData.gps.latitude]);
    },
    open(address, isUpdateEndAddress, isOnline) {
      console.log(address);
      if (isOnline) {
        this.isOnline = true;
      }
      this.address = address;
      this.isUpdateEndAddress = isUpdateEndAddress;
      this.show = true;
      this.city = this.oldCity ? this.oldCity : '';
      this.getCityList();
      this.timeer = setTimeout(() => {
        console.log('this.address====', this.address);
        if (this.address && (this.address.longitude || this.address.latitude)) {
          this.city = this.address.city;
        }
        this.initMap();
      }, 1);
    },
    getCity() {
      let _this = this;
      //根据经纬度查找所在省份、城市、区域
      var geocoder = new AMap.Geocoder({});
      let position = [this.address.longitude, this.address.latitude];
      geocoder.getAddress(position, function(status, result) {
        if (status === 'complete' && result.regeocode) {
          let addressComponent = result.regeocode.addressComponent;
          _this.city = addressComponent.city || addressComponent.province;
          _this.initMap();
        } else {
          _this.$iToast('根据经纬度查询地址失败');
        }
      });
    },
    initMap() {
      let _this = this;
      let tenant_code = this.SmartStorage.get('tenant') || this.SmartStorage.get('tenant_code');
      /**
       * todo
       * 将tenant_code 改为 novartis时，city 为会议城市
       */
      if (this.isNovartis) {
        this.city = this.SmartStorage.get('eventData').city;
      }
      this.getCurrentPs();
    },
    //标注当前城市
    setNowCity(city) {
      let cityList = this.cityList || [];
      cityList.map(value => {
        if (city == value.txt) {
          this.address = {
            latitude: value.itsExtData.gps.latitude,
            longitude: value.itsExtData.gps.longitude
          };
        }
      }, this);
    },
    async getCurrentPs() {
      let _this = this;
      //只有山德士给address重新赋值
      if (this.isNovartis) {
        await _this.setNowCity(_this.city);
      }
      AMapUI.loadUI(['misc/PositionPicker', 'misc/PoiPicker'], function(PositionPicker, PoiPicker) {
        let center;
        if (_this.address && (_this.address.longitude || _this.address.latitude)) {
          center = [_this.address.longitude, _this.address.latitude];
        }
        var map = (_this.map = new AMap.Map('containerMap', {
          resizeEnable: true,
          center: center,
          enableHighAccuracy: true,
          zoom: 16
          // mapStyle: "amap://styles/whitesmoke",
        }));
        //只有山德士给拖拽地点限制,仅会议城市
        // if(_this.isNovartis){
        //   _this.lockMapBounds();
        // }
        AMap.plugin(['AMap.Geolocation'], function() {
          var geo = new AMap.Geolocation({
            timeout: 10000,
            enableHighAccuracy: true, //是否使用高精度定位，默认:true
            position: 'RB',
            buttonOffset: new AMap.Pixel(10, 45),
            zoomToAccuracy: true
          });
          map.addControl(geo);
          if (_this.address && !_this.address.longitude && !_this.address.latitude) {
            geo.getCurrentPosition(function(status, result) {
              if (status == 'complete') {
                console.log(result.position);
                _this.SmartStorage.set('geolocation', {
                  lat: result.position.lat,
                  lng: result.position.lng,
                  formattedAddress: result.formattedAddress || result.address
                });
              }
            });
          }
          if (_this.address && _this.address.longitude) {
            map.setCenter([_this.address.longitude, _this.address.latitude]);
          }
        });
        var positionPicker = (_this.positionPicker = new PositionPicker({
          mode: 'dragMap',
          map: map,
          iconStyle: {
            //自定义外观
            url: positionImg,
            ancher: [24, 40],
            size: [42, 48]
          }
        }));
        positionPicker.on('success', function(positionResult) {
          _this.city =
            (positionResult && positionResult.regeocode && positionResult.regeocode.addressComponent && positionResult.regeocode.addressComponent.city) ||
            positionResult.regeocode.addressComponent.province;
          console.log(positionResult);
          _this.cityId = positionResult && positionResult.regeocode && positionResult.regeocode.addressComponent && positionResult.regeocode.addressComponent.adcode;
          if (!_this.city) {
            let citysearch = new AMap.CitySearch();
            citysearch.getLocalCity(function(status, result) {
              if (status === 'complete' && result.info === 'OK') {
                if (result && result.city && result.bounds) {
                  console.log(result);
                  _this.city = result.city;
                }
              }
            });
          }
          // _this.poiList = (positionResult || {regeocode: pois[{}]}).regeocode.pois
          _this.poiList = positionResult.regeocode.pois;
          let infoWindow = new AMap.InfoWindow({
            isCustom: true,
            offset: new AMap.Pixel(5, -35),
            content: `<div class="map-destination">${_this.poiList[0].name}</div>` //使用默认信息窗体框样式，显示信息内容
          });

          infoWindow.open(map, map.getCenter());
        });
        positionPicker.on('fail', function(positionResult) {
          _this.$iToast('拖拽失败');
        });
        positionPicker.start();
        map.panBy(0, 1);

        let poiPicker = new PoiPicker({
          input: 'pickerInput',
          extensions: 'all'
        });
      });
    },
    // 选择某个poi
   async choosePoi(item) {
      if (!this.city) {
        this.$iToast('请选择城市');
        return false;
      }
      let center = [item.location.lng, item.location.lat];
      let city= await this.geoGetAddress(center);
      // 展示点标记
      let address = {
        latitude: item.location.lat,
        longitude: item.location.lng,
        addressName: item.name,
        address: item.address||item.name,
        endPoi: item.id || item.location.id,
        type: item.type,
        city: item.cityname || this.city||city,
        cityId: this.cityId
      };
      console.log(address,"-------------------address");
      this.$store.commit('SETextdata', address);
      this.isUpdateEndAddress
        ? this.$iMessageBox.render({
            messageTitle: '请确认修改后的目的地：',
            messageTxt: address.addressName,
            onConfirm: function() {
              this.UpdateAddress(address);
            }.bind(this)
          })
        : this.close();
      // this.$emit("close", address);
      // this.show = false;
    },
    //神州行程中修改目的地
    async UpdateAddress(address) {
      let param = {
        itemId: this.address.itemId,
        elat: address.latitude,
        elng: address.longitude,
        endName: address.addressName,
        endAddress: address.address,
        orderId: this.address.orderId,
        endPoi: address.endPoi,
        endType: address.type,
        clng: this.address.longitude,
        clat: this.address.latitude,
        curName: this.address.curName
      };
      console.log(param);
      if (this.isOnline) {
        this.services.OnlineUpdateEndAddress(param).then(res => {
          if (res.success) {
            this.$iToast('目的地修改成功');
            this.close(address);
          }
        });
      } else {
        this.services.UpdateEndAddress(param).then(res => {
          if (res.success) {
            this.$iToast('目的地修改成功');
            this.close(address);
          }
        });
      }
    },
    geoGetAddress(adrss) {
      let AMap = (this.AMap = window.AMap);
      AMap.plugin('AMap.Geocoder', function() {
        var geocoders = new AMap.Geocoder({});
        geocoders.getAddress(adrss, function(status, result) {
           console.log(result,"-----------result")
          if (status === 'complete' && result.regeocode && result.regeocode.addressComponent) {
            console.log(result,"-----------result")
            let city = result.regeocode.addressComponent.city || result.regeocode.addressComponent.province;
            return city
          }
        });
      });
    },
    close(address) {
      this.$emit('close', address);
      this.goBack();
    },
    goBack() {
      this.show = false;
    },
    //限制地图显示范围
    lockMapBounds() {
      var bounds = this.map.getBounds();
      this.map.setLimitBounds(bounds);
    }
  }
};
</script>
<style lang="scss">
@import 'map';
</style>
