<template>
  <mt-popup v-model="searchVisiable" position="bottom" class="popup_box popup_mapSearch">
    <mapHead :goBack="goBack" :city="city" :chooseCity="chooseCity">
      <template slot="inputBox">
        <div class="inputBox">
          <input type="search" ref="searchInput" placeholder="小区/写字楼/学校等" v-model="keyword" />
        </div>
      </template>
    </mapHead>
    <div class="ele-address-list" v-if="poiList.length" @touchstart="hideKey()">
      <mapPoi :choosePoi="choosePoi" :poiList="poiList"></mapPoi>
    </div>
    <div v-else class="search-history">
      <div class="search-title" v-show="poiListhis.length">
        历史记录
        <span class="shopSearchClear" @click="clearHis">
          <Svgs color="#5B6C76" name="iconshanchu" />
        </span>
      </div>
      <ul class="search-history-container">
        <li v-for="txt in poiListhis" :key="txt" @click="chooseTxt(txt)">
          {{ txt }}
        </li>
      </ul>
    </div>
  </mt-popup>
</template>
<script>
import mapHead from './mapHead.vue';
import mapPoi from './mapPoi.vue';
import { mapMutations, mapGetters } from 'vuex';
export default {
  data() {
    return {
      tenantCode: this.SmartStorage.get('tenant'),
      isUpdateEndAddress: false,
      searchVisiable: false,
      keyword: '',
      poiList: [],
      poiListhis: [],
      chooseKey: ''
    };
  },
  components: { mapHead, mapPoi },
  props: ['city', 'UpdateAddress'],
  watch: {
    keyword: function() {
      this.search();
    }
  },
  methods: {
    ...mapMutations(['SET_ELE_ADDRESS']),
    async show(isUpdateEndAddress) {
      this.isUpdateEndAddress = isUpdateEndAddress;
      this.searchVisiable = true;
      this.$nextTick(x => {
        //正确写法
        this.$refs.searchInput.focus();
      });
      this.$refs.searchInput.focus();
      let poiListhis = await this.nativeGet(this.getHisKey);
    },
    hideKey() {
      this.$refs.searchInput.blur();
    },
    goBack(address) {
      this.keyword = '';
      this.poiList = [];
      this.searchVisiable = false;
      address && this.$emit('close', address);
    },
    async computeKeywords(txt) {
      this.chooseKey = txt;
      let localKeys = await this.nativeGet(this.computeKey);
    },
    computeKey(localKeys) {
      let txt = this.chooseKey;
      let flag = true;
      for (let i = 0; i < localKeys.length; i++) {
        if (localKeys[i] == txt) {
          localKeys.splice(i, 1);
        }
      }
      if (localKeys.length == 10) {
        localKeys.pop();
      }
      localKeys.unshift(txt);
      this.nativeSave(localKeys);
    },
    search() {
      if (!this.city) {
        this.$iToast('请选择城市');
        return;
      }
      let _this = this;
      console.log(this.city);
      // AMap.plugin('AMap.Autocomplete', function(){
      //   var autoOptions = {
      //     // input: 'pickerInput',
      //     city: _this.city,
      //     datatype:"all"
      //   }
      //   var autoComplete= new AMap.Autocomplete(autoOptions);
      //   autoComplete.search(_this.keyword, function(status, result) {
      //     _this.poiList = _this.keyword ? (result.tips || {}) : '';
      //     console.log('result------',result)
      //   })
      // })

      // AMap.plugin(["AMap.PlaceSearch"], function () {
      //   var placeSearch = new AMap.PlaceSearch({
      //     city: _this.city,
      //     citylimit: true,
      //     pageSize: 10,
      //     pageIndex: 1,
      //     extensions: "all",
      //     children: 1,
      //     show_fields:'children'

      //   });
      //   placeSearch.search(_this.keyword, function (status, result) {
      //     console.log('result====', result);
      //     _this.poiList = _this.keyword ? (result?.poiList?.pois || {}) : "";
      //   });
      // });

      AMap.plugin('AMap.Autocomplete', function() {
        var autoOptions = {
          city: _this.city,
          datatype: 'all'
        };
        var autoComplete = new AMap.Autocomplete(autoOptions);
        autoComplete.search(_this.keyword, function(status, result) {
          // let poiList = _this.keyword ? result.tips || {} : '';
          let poiList = result.tips?.filter(item => {
            if (item.location) {
              return item;
            }
          });

          console.log('result1111------', result);
          AMap.plugin(['AMap.PlaceSearch'], function() {
            var placeSearch = new AMap.PlaceSearch({
              city: _this.city,
              citylimit: true,
              pageSize: 10,
              pageIndex: 1,
              extensions: 'all',
              children: 1,
              show_fields: 'children'
            });
            placeSearch.search(_this.keyword, function(status, result) {
              console.log('result22222====', result);
              result.poiList.pois.filter(s => {
                poiList.filter(v => {
                  if (v.id == s.id) {
                    v.children = s.children;
                    v.type = s.type;
                  }
                });
              });
              _this.poiList = poiList;
            });
          });
        });
      });
    },
    chooseTxt(txt) {
      this.keyword = txt;
    },
    async choosePoi(item) {
      console.log('2222222', item);
      let _this = this;
      let city;

      if (!item.cityname) {
        //根据经纬度查找所在省份、城市、区域
        var geocoder = new AMap.Geocoder({});
        let position = [item.location.lng, item.location.lat];
        geocoder.getAddress(position, function(status, result) {
          if (status === 'complete' && result.regeocode) {
            let addressComponent = result.regeocode.addressComponent;
            city = addressComponent.city||addressComponent.province;
            _this.saveData(city,item);
          } else {
            _this.$iToast('根据经纬度查询地址失败');
          }
        });
      }else{
        _this.saveData(item.cityname,item);
      }
    },
    saveData(city,item) {
      let addressAlies;
      if (item.address.length > 1) {
        addressAlies = item.address;
      } else {
        addressAlies = item.name;
      }
      // 展示点标记
      let address = {
        latitude: item.location?.lat || '',
        longitude: item.location?.lng || '',
        addressName: item.name,
        address: addressAlies,
        type: item.type,
        endPoi: item.id,
        city: city
      };
      this.computeKeywords(item.name);
      this.$store.commit('SETextdata', address);
      this.isUpdateEndAddress
        ? this.$iMessageBox.render({
            messageTitle: '请确认修改后的目的地：',
            messageTxt: address.addressName,
            onConfirm: function() {
              this.UpdateAddress(address);
            }.bind(this)
          })
        : this.goBack(address);
    },
    getCity(item) {
      let _this = this;
      //根据经纬度查找所在省份、城市、区域
      var geocoder = new AMap.Geocoder({});
      let position = [item.location.lng, item.location.lat];
      geocoder.getAddress(position, function(status, result) {
        if (status === 'complete' && result.regeocode) {
          console.log('result====', result);
          let addressComponent = result.regeocode.addressComponent;
          addressComponent;

          // _this.city = addressComponent.city;
        } else {
          _this.$iToast('根据经纬度查询地址失败');
        }
      });
    },
    chooseCity() {
      this.$emit('chooseCity');
    },
    getHisKey(keyword) {
      this.poiListhis = keyword;
    },
    async clearHis() {
      this.nativeSave([]);
      let poiListhis = await this.nativeGet(this.getHisKey);
    },
    nativeSave: async function(params) {
      if (this.SmartStorage.get('isH5')) {
        localStorage.setItem('poiListhis', JSON.stringify(params));
      } else {
        if (window.flutter_inappwebview) {
          let data = {
            key: 'poiListhis',
            value: JSON.stringify(params)
          };
          console.log(JSON.stringify(data));
          window.flutter_inappwebview.callHandler('SmStorage.setString', JSON.stringify(data));
        } else {
          this.iJsBridge.call({
            method: 'SMDataStorage.setItem',
            postData: { ['poiListhis']: JSON.stringify(params) }
          });
        }
      }
    },
    nativeGet: async function(callBack) {
      console.log(this.SmartStorage.get('isH5'));
      let data = [];
      if (this.SmartStorage.get('isH5')) {
        data = JSON.parse(localStorage.getItem('poiListhis')) || [];
        callBack(data);
      } else {
        if (window.flutter_inappwebview) {
          let poiListhis = await window.flutter_inappwebview.callHandler('SmStorage.getString', 'poiListhis');

          callBack(JSON.parse(poiListhis || '[]'));
        } else {
          this.iJsBridge.call({
            method: 'SMDataStorage.getItem',
            postData: {
              key: ['poiListhis']
            },
            callback: function(datas) {
              callBack(JSON.parse(datas.poiListhis ? datas.poiListhis : '[]'));
            }
          });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import 'mapSearch';
</style>
