<template>
  <div class="ele-address-map-head">
    <div class="map-back-icon" @click="goBack">
      <Svgs color="#000" name="iconfanhui" v-if="!device"></Svgs>
      <div v-if="device">返回</div>
    </div>
    <div class="headerMapBox">
      <div class="address-map-search" @click="chooseCity">
        <div>{{this.city || '选择城市'}}</div>
      </div>
      <slot name="inputBox"></slot>
    </div>
  </div>
</template>

<script>
import { device } from '@/views/utils/common';
export default {
  props: ["goBack", "chooseCity",'city'],
  created() {
    this.device = device();
  }
};
</script>
<style lang="scss" scoped>
.ele-address-map-head {
    position: absolute;
    top: .17rem;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 47px;

    .map-back-icon {
        width: 15%;
        text-align: center;
        }

        div:last-child {
            width: 85%;
            position: relative;

            .address-map-search {
                position: absolute;
                top: 8px;
                left: 10px;

                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 60px;
                    float: left;
                    text-align: center;
                }
            }
        }

        input {
            border-radius:.04rem;
            // border: 1px solid #F5F5F5;
            height: 36px;
            line-height: 36px;
            width: 95%;
            text-align: left;
            outline: none;
            // padding-left: 90px;
            font-size: 14px;
            background: #fff;
            float: right;
            // box-shadow: 0 0 7px 0px #afadad;
            &::placeholder{
              color: $sub_txt_color;
            }
            &:disabled{
              color: $sub_txt_color;
            }
        }
    }
    .headerMapBox{
      width: 80% !important;
      background: #fff;
      box-shadow: 0 0 7px 0px #afadad;
      overflow: hidden;
      .inputBox{
        width: 76% !important;
        float: right;
      }
    }
</style>