<template>
  <Popup ref="pop" :visible="visible" bg="#fff" position="Right">
    <template v-slot:popContent>
      <div :style="pageStyle" class="cityContent">
        <div class="searchHeight">
          <div class="shopSearchRow">
            <span>
              <Svgs
                color="#C0C4CD"
                classes="shopSearchSvg"
                name="iconsousuo"
              ></Svgs>
              <input
                type="text"
                placeholder="请输入城市名称"
                v-model="cityFilter"
                @input="filterCity"
              />
            </span>
            <span class="searchCancel" @click="openPop">取消</span>
          </div>

          <div class="searchHis">
            <p class="searchHeadTxt">当前定位</p>
            <ul class="searchVague">
              <li @click="searchChoose(nowCity)">
                <Svgs
                  color="#007AFF"
                  classes="vagueSvg"
                  name="icondaohang-tianchong"
                ></Svgs>
                <span v-if="nowCity[cityTxt]">{{ nowCity[cityTxt] }}</span>
                <span v-if="!nowCity[cityTxt]">{{ positionStatus }}</span>
                <span class="rePos" @click="rePos($event)">重新定位</span>
              </li>
            </ul>
          </div>

          <div class="searchHis">
            <p class="searchHeadTxt">热门城市</p>
            <ul class="searchHisList">
              <!-- :class="{'searchHisOn':his.pos}" -->
              <div v-for="(his, indexs) in cityHis" :key="indexs">
                <li v-if="his[cityTxt]" @click="chooseOk(his)">
                  {{ his[cityTxt] }}
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div class="cityList" id="#">
          <div
            class="searchHis"
            :id="pro.key"
            v-for="(pro, index) in searchCityList"
            :key="index"
          >
            <div v-if="pro.filter">
              <p class="searchHeadTxt">{{ pro.key }}</p>
              <div class="searchVague searchVague2">
                <ul
                  v-for="(city, ind) in pro.arr"
                  :key="ind"
                  @click="searchChoose(city)"
                >
                  <li v-if="!city.filter">
                    {{ city[cityTxt] }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <ul class="cityPoint">
          <!-- 使用城市列表里面的字母序号 -->
          <li v-for="(item, index) in searchCityList" :key="index">
            <a :href="'#' + item.key">{{ item.key }}</a>
          </li>
          <!-- <li v-for="(n,index) in point" :key="index"><a :href="'#'+n">{{n}}</a></li> -->
        </ul>
        <div id="citycontainer"></div>
      </div>
    </template>
  </Popup>
</template>

<script>
// import remoteLoad from './remoteLoad'

export default {
  name: "SearchCityCar",
  props: [
    "cityList", //城市数据源
    "txt", //城市中文
    "val", //城市val
    "keys", //排序依据如"CD"=成都
    "visible", //显示隐藏
  ],
  watch: {
    cityList: function () {
      this.setCityList();
      this.setNowCity(this.nowCitys);
    },
  },
  data() {
    return {
      keyword: "",
      pageStyle: {},
      point: ["#"],
      searchCityList: [],
      cityHis: [],
      nowCity: {},
      cityTxt: "",
      cityVal: "",
      cityKey: "",
      cityFilter: "",
      chooseCitys: {},
      nowCitys: {},
      openFlag: false,
      positionStatus: "",
    };
  },
  created() {
    this.cityTxt = this.txt || "txt";
    this.cityVal = this.val || "txt";
    this.citykey = this.keys || "pyVal";
    this.positionStatus = "定位中，请稍候...";
    this.setStyle();
    // this.setAZ();
    this.getHis();
  },
  mounted() {
    this.rePos();
  },
  methods: {
    //按26字母循环分类城市数据
    setCityList() {
      this.searchCityList = [];
      let cityTemp = [];
      for (let i = 0; i < 26; i++) {
        cityTemp.push({
          key: String.fromCharCode(65 + i),
          filter: true,
          arr: [],
        });
      }
      this.cityList.map((value) => {
        let num = value[this.citykey].split("")[0].charCodeAt() - 65;
        cityTemp[num].arr.push(value);
      }, this);

      cityTemp.map((item) => {
        if (item.arr && item.arr.length > 0) {
          this.searchCityList.push(item);
        }
      });
    },
    filterCity() {
      for (let q = 0; q < this.searchCityList.length; q++) {
        let arr = this.searchCityList[q].arr;
        let flag = false;
        for (let j = 0; j < arr.length; j++) {
          if (arr[j][this.cityVal].indexOf(this.cityFilter) != -1) {
            arr[j].filter = false;
            flag = true;
          } else {
            arr[j].filter = true;
          }
        }
        this.searchCityList[q].filter = flag;
      }
    },
    //获取已选择过的城市
    async getHis() {
      await this.nativeGet(this.getHisCity);
      // console.log(cityHis)
    },
    getHisCity(city) {
      this.cityHis = city;
    },
    backList() {
      this.$router.back();
    },
    //地图初始化
    async rePos(e) {
      // if (e) {
      //     e.stopPropagation();
      // }
      // if (window.AMap && window.AMapUI) {
      //     this.initMap();
      //     // 未载入高德地图API，则先载入API再初始化
      // }
      // e
      this.initMap();
    },
    //地图定位
    initMap() {
      this.positionStatus = "定位中，请稍候...";
      // let AMap = window.AMap;
      // var map = new AMap.Map('citycontainer', {
      //     resizeEnable: true
      // });
      // let _this = this;
      // map.plugin('AMap.CitySearch', function () {
      //     var citySearch = new AMap.CitySearch()
      //     citySearch.getLocalCity(function (status, result) {
      //         if (status === 'complete' && result.info === 'OK') {
      //             _this.positionStatus = '';
      //             _this.setNowCity(result.city);
      //         } else {
      //             _this.$toastBox('定位失败！请手动选择！');
      //         }
      //     })
      // })
      let _this = this;
      let citysearch = new AMap.CitySearch();
      citysearch.getLocalCity(function (status, result) {
        console.log("status===", status);
        console.log("result===", result);
        if (status === "complete" && result.info === "OK") {
          if (result && result.city) {
            console.log(result);
            _this.positionStatus = "";
            _this.setNowCity(result.city);
            _this.city = result.city;
          }else{
            _this.$toastBox('定位失败！请手动选择！');
          }
        }
      });
    },
    //标注当前城市
    async setNowCity(city) {
      this.nowCitys = city;
      let cityList = this.cityList || [];
      cityList.map((value) => {
        if (city == value[this.cityTxt]) {
          this.$set(value, "pos", true);
          this.nowCity = value;
        } else {
          this.$set(value, "pos", false);
        }
      }, this);
      await this.nativeGet(this.setCityHost);
    },
    setCityHost(localCity) {
      let city = this.nowCitys;
      for (let i = 0; i < localCity.length; i++) {
        if (localCity[i][this.cityVal] == city) {
          this.$set(localCity[i], "pos", true);
        } else {
          this.$set(localCity[i], "pos", false);
        }
      }
      this.cityHis = localCity;
      if (typeof localCity != "object") {
        this.nativeSave(localCity);
      }
    },
    openPop() {
      if (!this.openFlag) {
        this.openFlag = true;
      }
      this.cityFilter = "";
      this.filterCity();
      this.$refs.pop.changePop();
    },
    nativeSave: async function (params) {
      let isH5 = sessionStorage.getItem("isH5");
      if (isH5) {
        localStorage.setItem("searchCity", JSON.stringify(params));
      } else {
        if (window.flutter_inappwebview) {
          let data = {
            key: "poiListhis",
            value: JSON.stringify(params),
          };
          console.log(JSON.stringify(data));
          window.flutter_inappwebview.callHandler(
            "SmStorage.setString",
            JSON.stringify(data)
          );
        } else {
          this.iJsBridge.call({
            method: "SMDataStorage.setItem",
            postData: { ["searchCity"]: JSON.stringify(params) },
          });
        }
      }
    },
    nativeGet: async function (callBack) {
      let isH5 = sessionStorage.getItem("isH5");
      let data = [];
      if (isH5) {
        data = JSON.parse(localStorage.getItem("searchCity")) || [];
        callBack(data);
      } else {
        if (window.flutter_inappwebview) {
          let poiListhis = await window.flutter_inappwebview.callHandler(
            "SmStorage.getString",
            "poiListhis"
          );
          callBack(JSON.parse(poiListhis || "[]"));
        } else {
          this.iJsBridge.call({
            method: "SMDataStorage.getItem",
            postData: {
              key: ["searchCity"],
            },
            callback: function (datas) {
              // console.log(JSON.parse(datas.searchCity || ''), 222)
              // return JSON.parse(datas.searchCity || '');
              callBack(JSON.parse(datas.searchCity ? datas.searchCity : "[]"));
            },
          });
        }
      }
    },
    //点击列表保存已选择过的城市
    async searchChoose(city) {
      this.chooseCitys = city;
      await this.nativeGet(this.setChoose);
      // console.log(localCity)
    },
    setChoose(localCity) {
      let city = this.chooseCitys;
      let flag = true;
      for (let i = 0; i < localCity.length; i++) {
        if (localCity[i][this.cityVal] == city[this.cityVal]) {
          flag = false;
        }
      }
      if (flag) {
        if (localCity.length == 5) {
          localCity.pop();
        }
        localCity.unshift(city);
        this.nativeSave(localCity);
        this.getHis();
      }
      this.chooseOk(city);
    },
    //选择完毕
    chooseOk(city) {
      this.$emit("on-ok", city);
      this.openPop();
    },
    //循环右侧定位（右边26字母）
    setAZ() {
      for (let i = 0; i < 26; i++) {
        this.point.push(String.fromCharCode(65 + i));
      }
    },
    //设置弹框高度
    setStyle() {
      let height = window.innerHeight + "px";
      this.pageStyle = {
        height: height,
        background: "#fff",
      };
    },
  },
};
</script>

<style lang="scss">
@import "SearchCityCar";
</style>